import { Component } from '@angular/core';
import { FaIconsRegistry, faMagnifyingGlass, faPlus } from '@ui-core/icon';

@Component({
  selector: 'app-processor-dashboard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'processor-dashboard-ui';

  constructor(private faIconsRegistry: FaIconsRegistry) {
    this.faIconsRegistry.registerIcons([faPlus, faMagnifyingGlass]);
  }
}