import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StartNewTaskDataModel, UserAssignedToTaskDataModel } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ProcessorService {


  readonly gtBaseUrl = `${environment.genericTaskBase.apiUrl}`

  constructor(private httpClient: HttpClient) { }

  updateStartNewTask(processId: string): Observable<StartNewTaskDataModel> {
    return this.httpClient.post<StartNewTaskDataModel>(`${this.gtBaseUrl}` + `/gentask/v1/process/${processId}/task/start`, null);
  }

  updateStartNewEmail(processId: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.igpsBase.apiUrl}` + `/emailservice/dashboard/v1/process/${processId}/email/start`, null);
  }

  getStatusTaskAssignedToUser(processId: string): Observable<UserAssignedToTaskDataModel> {
    return this.httpClient.get<UserAssignedToTaskDataModel>(`${this.gtBaseUrl}` + `/gentask/v1/process/${processId}/task/assigned`);
  }
}
