export interface TaskSummarySearchDataModel {
    totalItemCount: number,
    totalPageCount: number,
    pageNumber: number,
    count: number,
    pageSize: number,
    items: TaskSummaryDataModel[],
}

export interface TaskSummaryDataModel {
    id: string,
    fileNumber: string,
    totalNotesComments: number,
    taskType: string,
    priority: number,
    slaInSeconds: string,
    status: string,
    office: string,
    officer: string
}
export enum SourceType {
    Task = 1,
    Email = 2
}