<lib-toast [toastMessage]="toasterMessage" [error]="isError"></lib-toast>
<igps-lib-spinner [showSpinner]="showSpinner"></igps-lib-spinner>
<!-- <h3>{{processName}} Dashboard</h3> -->

<button id="btnStartNewTask" mat-flat-button (click)="onStartNewTask()" *ngIf="taskSourceType==1"
  [disabled]="(buttonDisableObservable | async)"
  class="mat-focus-indicator ig2 button button--primary mat-flat-button mat-button-base">
  <lib-icon name="plus" size="standard" theme="white"></lib-icon>
  Start New Task
</button>
<button id="btnStartNewTask" mat-flat-button (click)="onStartNewTask()" *ngIf="taskSourceType==2"
  [disabled]="(buttonDisableObservable | async)"
  class="mat-focus-indicator ig2 button button--primary mat-flat-button mat-button-base">
  <lib-icon name="plus" size="standard" theme="white"></lib-icon>
  Start New Entry
</button>
